<template>
  <div class="c-authors">
    <FHBreadcrumbs v-if="crumbs"
      class="c-unit__breadcrumbs u-py u-px"
      :crumbs="crumbs" />

    <div v-if="title" class="c-unit__container u-mb">
      <div class="o-wrapper u-pr">
        <VHeading header="h1" class="c-unit__title o-heading">
          {{ title }}
        </VHeading>
      </div>
    </div>

    <ContentLoader v-else height="200">
      <rect x="1rem" y="0rem" width="30%" height="1rem"></rect>
      <circle cx="75" cy="75" r="50" />
      <circle cx="200" cy="75" r="50" />
      <circle cx="325" cy="75" r="50" />
      <rect x="1.5rem" y="130" width="25%" height=".35rem"></rect>
      <rect x="1.5rem" y="140" width="25%" height=".35rem"></rect>
      <rect x="1.5rem" y="150" width="15%" height=".35rem"></rect>
      <rect x="150" y="130" width="25%" height=".35rem"></rect>
      <rect x="150" y="140" width="25%" height=".35rem"></rect>
      <rect x="150" y="150" width="15%" height=".35rem"></rect>
      <rect x="275" y="130" width="25%" height=".35rem"></rect>
      <rect x="275" y="140" width="25%" height=".35rem"></rect>
      <rect x="275" y="150" width="15%" height=".35rem"></rect>
    </ContentLoader>

    <div v-if="content.length"
      class="c-authors__wrapper u-px">
      <div v-for="lesson in content"
        :key="lesson.id"
        class="c-authors__section" >
        <figure v-if="lesson.cover"
          class="c-authors__banner">
          <picture class="c-authors__banner-picture">
            <img
              ref="cover"
              :src="lesson.cover.image_type.file.url"
              :alt="lesson.cover.image_type.altText"
              :title="lesson.cover.image_type.license ? lesson.cover.image_type.license.credit : ''"
              class="c-authors__banner-img">
          </picture>
          <div class="o-wrapper" v-if="lesson.cover.image_type.description">
            <figcaption class="c-authors__banner-figcaption">{{ lesson.cover.image_type.description }}</figcaption>
          </div>
          <VHeading header="h2" class="c-authors__title o-heading u-tac">
            {{ lesson.name }}
          </VHeading>
          <div class="c-authors__desc" v-if="lesson.description" v-md-html="lesson.description" />
        </figure>
      </div>
    </div>
  </div>
</template>



<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { ContentLoader } from 'vue-content-loader'
import { fetchUnitBySlug, fetchUnitLessons } from '@/services/strapi'
// import { parseLearningElements, componentNameNormalize } from '@/utils'
import VHeading from '@forlagshuset/v-heading'
import FHBreadcrumbs from '@forlagshuset/v-breadcrumbs'

export default {
  name: 'Authors',

  components: {
    ContentLoader,
    VHeading,
    FHBreadcrumbs
  },

  setup(props, ctx) {
    const appSlug = ctx.root.$store.state.appConfig.slug
    const unitSlug = computed(() => appSlug === 'aktor' ? 'aktor-om-forfatterne' : 'aktor-om-forfatterne-nn')
    const title = ref('')
    const description = ref('')
    const crumbs = ref([])
    const content = ref([])

    onMounted (async () => {
      const response = await fetchUnitBySlug(unitSlug.value)
      const unit = response

      title.value = unit.name

      description.value = unit.description

      content.value = await fetchUnitLessons(unitSlug.value)

      crumbs.value.push({
        type: 'unit',
        name: unit.name,
        slug: unit.slug
      })

    })

    return {
      unitSlug,
      title,
      description,
      crumbs,
      content,
      // componentNameNormalize
    }
  },
}
</script>


<style lang="scss">
.c-authors {
  min-height: calc(100vh - 17rem);
}
.c-authors__wrapper {
  display: grid;
  grid-template-columns: repeat(1, fit-content(345px));
  margin: 0 auto;

  @include bp(medium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include bp(large) {
    grid-template-columns: repeat(3, 1fr);
    width: $xlarge;
  }
}
.c-authors__section {
  &.c-image-element {
    padding: 0;
  }

  .c-text-element__title {
    h3 {
      text-align: center;
    }
  }

  @include bp(medium) {
    .c-image-element__img {
      width: auto;
      height: 100%;
    }
  }
}
</style>